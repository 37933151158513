import { useEffect, useState } from 'react';
import { Box, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../Firebase/firebase-config';
import { logout, signin } from "../Firebase/firebase-auth";
import { getRedirectResult, onAuthStateChanged } from "firebase/auth";
import { async } from '@firebase/util';

const NavBar = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(async() => {
      const result = await getRedirectResult(auth);  
      console.log(result);
  }, []);

  const signIn = () => {
    signin();
  }

  return (
    <Box
      sx={{
        background: "#6e26b5",
        display: {
          xs: "none",
          sm: "flex",
        },
        justifyContent: "space-between",
      }}
    >
      <Container>
        <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px", paddingTop: "7px", color: "white" }}>
          <Box sx={{ display: "flex", gap: 2, paddingTop: "10px" }}>
            <Box>
              <h3
                onClick={() => navigate("/")}
                style={{ cursor: 'pointer' }} // Inline style to make the cursor a pointer
              >
                Home
              </h3>
            </Box>
            <Box>
              <h3
                onClick={() => navigate("/history")}
                style={{ cursor: 'pointer' }} // Inline style to make the cursor a pointer
              >
                Bookmarks
              </h3>
            </Box>
            <Box><h3>Comics</h3></Box>
            <Box><h3>Profile</h3></Box>
          </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#322f36', // Set the background color
              color: '#FFFFFF',           // Set the text color
              '&:hover': {
                backgroundColor: '#55505c', // Set the background color on hover
              },
            }}
            onClick={user ? logout : signIn}
          >
            {user ? "Sign Out" : "Sign In"}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NavBar;

