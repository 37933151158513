import { auth, provider } from '../Firebase/firebase-config';
import { signInWithPopup, signOut, signInWithRedirect } from "firebase/auth";

export async function signin() {
  if (auth.currentUser) {
    console.log('User already signed in');
    return false;
  }

  try {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    if (isMobile) {
      await signInWithRedirect(auth, provider);
    } else {
      await signInWithPopup(auth, provider);
    }
  } catch (error) {
    console.error('Error signing in:', error);
  }
  return true;
}

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error('Error signing out:', error);
  }
}

