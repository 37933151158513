import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import { getFirestore } from "firebase/firestore";  

const firebaseConfig = {
  apiKey: "AIzaSyDmBMBV9f8bEhgIqpKpe3BTBF9UFxC9A5k",
  authDomain: "mangaandmanhwahistory.firebaseapp.com",
  projectId: "mangaandmanhwahistory",
  storageBucket: "mangaandmanhwahistory.appspot.com",
  messagingSenderId: "135569602179",
  appId: "1:135569602179:web:1f912f1e1e164667a1cdb6",
  measurementId: "G-RXHGE18V5L"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
