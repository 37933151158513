import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../Firebase/firebase-config';
import { useLocation } from 'react-router-dom';
import { addDoc, collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';

const Descriptions = ({ title, img, genre, description, rating }) => {
  const [user, setUser] = useState(null);
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const rawPathname = location.pathname;
  const currentUrl = rawPathname.replace(/&/g, '/').replace(/^\/description\//, ''); useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setCheckedValue();
  }, [user]);

  const setCheckedValue = async () => {
    if (user) {
      const historyRef = collection(db, `users/${user.uid}/mangaHistory`);
      const q = query(historyRef, where('url', '==', currentUrl));
      const querySnapshot = await getDocs(q);
      setChecked(!querySnapshot.empty);
    }
  };

  const handleClick = async () => {
    if (checked) {
      await deleteManga();
    } else {
      await addManga();
    }
    setChecked(prev => !prev);
  };

  const truncateStringToWords = (str, maxWords) => {
    const wordsArray = str.split(/\s+/);
    if (wordsArray.length > maxWords) {
      const truncatedArray = wordsArray.slice(0, maxWords);
      return truncatedArray.join(' ') + '...';
    }
    return str;
  };

  const addManga = async () => {
    if (user) {
      const historyRef = collection(db, `users/${user.uid}/mangaHistory`);
      const q = query(historyRef, where('url', '==', currentUrl));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        await addDoc(historyRef, {
          img: img,
          name: title,
          url: currentUrl,
        });
      } else {
        console.log('Document with this URL already exists.');
      }
    }
  };
  async function deleteManga() {
    const historyRef = collection(db, `users/${user.uid}/mangaHistory`);
    const q = query(historyRef, where('url', '==', currentUrl));
    const querySnapshot = await getDocs(q);
    const id = querySnapshot.docs[0].id;
    await deleteDoc(doc(db, `users/${user.uid}/mangaHistory`, id));
  }
  return (
    <Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, alignItems: { xs: "center", md: "flex-start" }, padding: "10px" }}>
      <Box
        component="img"
        src={img?.includes("kunmanga") ? `https://proxyserver632.vercel.app/proxy?url=${img}` : img}
        sx={{ flex: 1, maxHeight: "300px", objectFit: "contain", borderRadius: "8px" }}
        alt={title || "Image"}
      />
      <Box sx={{ flex: 3, marginLeft: "2%", color: "white", maxWidth: { xs: "350px", sm: "500px" }, overflow: "hidden" }}>
        <Typography variant="h5" component="h1">{title}</Typography>
        <Typography variant="body1" component="h3">{rating}</Typography>
        <Typography variant="body2" component="p">
          {truncateStringToWords(description || "", 100).replace("Show more", "")}
        </Typography>
        <IconButton onClick={handleClick}>
          {checked ? (
            <FavoriteIcon sx={{ color: 'pink', fontSize: 40 }} />
          ) : (
            <FavoriteBorderIcon sx={{ color: 'pink', fontSize: 40 }} />
          )}
          <Typography variant="h6" sx={{ color: 'pink' }}>Favourite</Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Descriptions;
