import { Box, Container, createTheme, ThemeProvider, useMediaQuery } from "@mui/material"
import Content from "../History/Global/Content"

const theme = createTheme();
const History = () => {
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <ThemeProvider theme={theme}>
      {isMediumOrLarger ? (
        <Container maxWidth="lg">
          <Content />
        </Container>
      ) : (
        <Box>
          <Content />
        </Box>
      )}
    </ThemeProvider>
  )
}

export default History
