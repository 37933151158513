import { Box, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { db, auth } from '../../Firebase/firebase-config'
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc, query, where } from 'firebase/firestore';
import { useEffect, useState } from "react";
import { onAuthStateChanged } from 'firebase/auth';

export const CustomGrid = () => {
  const [user, setUser] = useState(null);
  const [mangas, setMangas] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchManga(currentUser);
      }
    });
    return () => unsubscribe();
  }, []);

  async function fetchManga(currentUser) {
    if (!currentUser) return;
    const historyRef = collection(db, `users/${currentUser.uid}/mangaHistory`);
    const snapshot = await getDocs(historyRef);
    const mangas = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    mangas.map((manga) => {
      console.log(manga.img);
    })
    setMangas(mangas);
  }

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: { xs: "10px", md: "0px" },marginLeft:"5px"}}>
        {mangas.map((manga)=>{
          return <GridContainerlayout name={manga.name} img={manga.img} url={manga.url} />
        })}
      </Grid>
    </>
  )
}
const GridContainerlayout = ({ name, img, url }) => {
  return (
    <Grid item xs={4} sm={3} lg={2.4}>
      <Items name={name} img={img} url={url} />
    </Grid>
  )
}
const Items = ({ name, img, url }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/description/${url.replaceAll("/", "&")}`)
  }
  return (
    <Box
      sx={{ height: "100%", overflow: "hidden", position: "relative", cursor: "pointer" }}
      onClick={handleClick}
    >
      <img
        src={img.includes("kunmanga") ? `https://proxyserver632.vercel.app/proxy?url=${img}` : img}
        alt={name}
        style={{
          borderRadius: "5px",
          width: "100%",
          height: "auto",
          objectFit: "cover",
          display: "block",
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '100px', // Adjust this height based on how much of the image you want to cover
          background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
          borderRadius: '0 0 5px 5px',
        }}
      />
      <Typography
        variant="h6"
        align="start"
        sx={{
          position: 'absolute',
          bottom: '8px',
          left: '8px',
          color: 'white',
          padding: '4px 8px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 'calc(100% - 16px)',
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};
export default CustomGrid
