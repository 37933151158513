import { Button } from "@mui/material";
import { Box } from "@mui/system"
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";


const Content = () => {
  const [images, setImages] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [chaptername ,setChaptername] = useState("")
  const navigate = useNavigate();

  const id = searchParams.get('id');
  const server = searchParams.get('server');
  const mainurl = searchParams.get('mainurl');

  const fetchData = async (id, server, mainurl) => {
    const response = await fetch(`https://mangaand-manhwa-api.vercel.app/${server}?info=${mainurl}`)
    const datafromapi = await response.json()

    const imagetofetch = datafromapi.chapters[id].url
    console.log(imagetofetch)
    const responseimages = await fetch(`https://mangaand-manhwa-api.vercel.app/${server}?image=${imagetofetch}`)
    const imagesfromapi = await responseimages.json()
    console.log(imagesfromapi)
    setChaptername(datafromapi.chapters[id].title)
    setImages(imagesfromapi)
  }
  useEffect(() => {
    fetchData(id, server, mainurl)
  }, [id, server, mainurl])

  const handlePrev = () => {
    navigate(`/reader?id=${parseInt(id)+1}&server=${server}&mainurl=${mainurl}`)
    window.location.reload()
  }
  const handleNext = () => {
    navigate(`/reader?id=${parseInt(id)-1}&server=${server}&mainurl=${mainurl}`)
    window.location.reload()
  }
  return (


    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Centers content vertically
        alignItems: 'center', // Centers content horizontally
      }}
    > 
      <h2 style={{color:"white"}}>{chaptername}</h2>
        {
        images?.map((image, index) => (
          <img
            src={image.img.includes("kun") ? image.img : `https://proxy-server-for-manga-api.vercel.app/?url=${image.img}`}
            key={image.id}
            style={{
              maxWidth: '100%',
              width: { xs: '100%', sm: '90%', md: '60%', lg: '75%' },
              display: 'block', // Ensures the image respects margin auto
              margin: '0 auto', // Center image horizontally if needed
            }}
          />
        ))
      }
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "10%", paddingX: "20%", gap: 2 }}>
        <Button variant="contained" onClick={handlePrev}>Prev</Button>
        <Button variant="contained" onClick={handleNext}>Next</Button>
      </Box>
    </Box>
  );
};

export default Content
