import { Button, Container, IconButton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signin } from "../Firebase/firebase-auth";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { auth } from "../Firebase/firebase-config";
import { onAuthStateChanged } from "firebase/auth";


const TopBar = () => {
  const [user, setUser] = useState(null);
  const [text, setText] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    setText(e.target.value)
  }

  const handleSubmit = () => {
    navigate(`/search/${text.replaceAll(" ", "-")}`)
  }

  return (
    <Box sx={{ background: { xs: "#6e26b5", sm: "#000000" } }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingY: "5px",
          }}
        >
          <Box sx={{ padding: "0px", color: "white" }}><img src="https://asuracomic.net/images/logo.webp" width="40px" style={{ marginRight: "5px" }} onClick={() => {
            navigate("/")
          }} /></Box>
          <TextField
            placeholder="Search"
            variant="outlined"
            size="small"
            onChange={handleChange}
            onSubmit={handleSubmit}
            sx={{
              height: "70%",
              width: {
                xs: "85%",
                sm: "auto",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "white",
              },
              "& .MuiInputBase-input": {
                color: "white",
              },
              borderRadius: "10px",
              background: "#333333",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent", // Remove border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent", // Remove border color when focused
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSubmit}>
                    <SearchIcon sx={{ color: "white" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: { vs: "block", sm: "none" } }}>
            <IconButton onClick={() => { navigate("/history") }}>
              <BookmarkIcon sx={{ color: "#3d8fdb", fontSize: 30 }} />
            </IconButton>
          </Box>
          <Box sx={{ display: { vs: "block", sm: "none" } }}>
            <img
              src={user ? user.photoURL : '/user.png'}
              style={{
                width: '40px',         // Ensure width and height are the same
                height: '40px',        // Ensure width and height are the same
                borderRadius: '50%',   // Makes the image circular
                marginLeft: '5px',
                cursor: 'pointer',
                objectFit: 'cover',    // Ensures the image covers the circular area
              }}
              onClick={signin}
              alt="User Profile"
            />          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TopBar;
