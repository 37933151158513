import { Box } from "@mui/system"
import CustomGrid from "../Left/CustomGrid"

const Content = () => {
  return (
    <Box>
      <CustomGrid/> 
    </Box>
  )
}

export default Content
